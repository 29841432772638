import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "../containers/LandingPage";
import AdminRouter from "./admin.router";

const RootRouter: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/admin" component={AdminRouter} />
      </Switch>
    </Router>
  );
};

export default RootRouter;
