import { PropsWithChildren, FC } from "react";
import * as S from "./styles";

export interface ContainerProps {
  padding?: string;
  border?: string;
}

const Container: FC<PropsWithChildren<ContainerProps>> = ({
  padding,
  border,
  children,
}: PropsWithChildren<ContainerProps>) => (
  <S.Container padding={padding} border={border}>
    {children}
  </S.Container>
);

export default Container;
