import React, { FunctionComponent, PropsWithChildren } from "react";
import { StyledButton } from "./styles";

interface Props {
  color?: string;
  width?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  [key: string]: any;
}

const Button: FunctionComponent<PropsWithChildren<Props>> = ({
  color,
  width,
  children,
  onClick,
}: PropsWithChildren<Props>) => (
  <StyledButton color={color} width={width} onClick={onClick}>
    {children}
  </StyledButton>
);

export default Button;
