// import { lazy } from "react";
import { Row, Col } from "antd";
// @ts-ignore
import Zoom from "react-reveal/Zoom";
import { withTranslation } from "react-i18next";

import useForm from "./useForm";
import validate from "./validationRules";

import * as S from "./styles";

import Block from "../Block";
import Input from "../../common/Input";

// const Block = lazy(() => import("../Block"));
// const Input = lazy(() => import("../../common/Input"));
import Button from "../../common/Button";
import TextArea from "../../common/TextArea";

interface Props {
  title?: string;
  content?: string;
  t?: any;
  id?: string;
}

const Contact = ({ title, content, id, t }: Props) => {
  const { values, errors, handleChange, handleSubmit } = useForm(validate);

  const ValidationType = ({ type }: { type: string }) => {
    const ErrorMessage = (errors as any)[type];
    return (errors as any)[type] ? (
      <Zoom cascade>
        <S.Span>{ErrorMessage}</S.Span>
      </Zoom>
    ) : (
      <S.Span />
    );
  };

  return (
    <S.ContactContainer id={id}>
      <S.Contact>
        <Row justify="space-between" align="middle">
          <Col lg={12} md={11} sm={24}>
            <Block title={title} content={content} />
          </Col>
          <Col lg={12} md={12} sm={24}>
            <S.FormGroup autoComplete="off" onSubmit={handleSubmit}>
              <Col span={24}>
                <Input
                  type="text"
                  name="name"
                  id="Name"
                  placeholder="Your Name"
                  value={(values as any).name || ""}
                  onChange={handleChange}
                />
                <ValidationType type="name" />
              </Col>
              <Col span={24}>
                <Input
                  type="text"
                  name="email"
                  id="Email"
                  placeholder="Your Email"
                  value={(values as any).email || ""}
                  onChange={handleChange}
                />
                <ValidationType type="email" />
              </Col>
              <Col span={24}>
                <TextArea
                  placeholder="Your Message"
                  value={(values as any).message || ""}
                  name="message"
                  id="Message"
                  onChange={handleChange}
                />
                <ValidationType type="message" />
              </Col>
              <S.ButtonContainer>
                <Button name="submit" type="submit">
                  {t("Submit")}
                </Button>
              </S.ButtonContainer>
            </S.FormGroup>
          </Col>
        </Row>
      </S.Contact>
    </S.ContactContainer>
  );
};

export default withTranslation()(Contact);
