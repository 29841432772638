import React from "react";

interface Props {
  src: string;
  width?: string;
  height?: string;
  [key: string]: any;
}

const SvgIcon: React.FC<Props> = ({ src, width, height }: Props) => (
  <img src={`/img/svg/${src}`} alt={src} width={width} height={height} />
);

export default SvgIcon;
