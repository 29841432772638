import { FC, ChangeEventHandler } from "react";
import { withTranslation } from "react-i18next";

import * as S from "./styles";

interface InputProps {
  id?: string;
  name?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  t: any;
  [key: string]: any;
}

const Input: FC<InputProps> = ({
  id,
  name,
  placeholder,
  onChange,
  t,
}: InputProps) => (
  <S.Container>
    <label htmlFor={name}>{t(id)}</label>
    <S.Input
      spellCheck={false}
      placeholder={t(placeholder)}
      name={name}
      id={name}
      onChange={onChange}
    />
  </S.Container>
);

export default withTranslation()(Input);
